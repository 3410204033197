import React, { useEffect, useState } from 'react';
import Client from 'shopify-buy';
import { Button, Input } from '@chakra-ui/react'
import Navbar from '../../components/NavBar';
import { Link, navigate } from 'gatsby';
import Sticker from '../../images/Sticker_Ton_Yosemite.jpg';
import nyc from '../../images/nyc_daily.png';
import room from '../../images/room_cans.png';
import StickerPage from '../../components/sticker-page';


const Stickers = () => {

  const trackEmail = (e) => {
      window.analytics.identify({
        email: e.target.value
      })
  }
  
  return (
      <StickerPage
        stickerName="Yosemite"
        stickerImage={Sticker}
      />
  )
};

export default Stickers;
